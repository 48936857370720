import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  CutTag,
  DataMatchSelection,
  DataMatchStatus,
  EPlusFolder,
  FileStatus,
  MetricName,
  MetricNamespace,
  TestFileCheck,
  TextBlock,
  TextcutRectangle,
  UndoEvent,
} from '../../types'
import { filter } from 'lodash'
import {
  createBindingAndAddOnSelectionHandler,
  enableEvents,
  generateExcelColumns,
  getColumnIndex,
  lettersToNumber,
  loadTextFromRange,
  numberToLetters,
} from '../../workbook'
import { ClockLoader } from 'react-spinners'
import { nanoid } from 'nanoid'
import {
  calculateHeightandWidth,
  findTOpLeftPointFromVertices,
} from '../../utils/spatial'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addMultipleReferences } from '../../slice/referenceSlice'
import CustomDisclosure from '../CustomDisclosure'
import { pushToUndoStack } from '../../slice/undoSlice'
import ErrorMsg from '../ErrorMsg'
import {
  getFileAndOcrBatch,
  getFileStructure,
  getOfficeErrorTitle,
} from '../../utils/common'
import { isCustomEvent, isOfficeError, isTextBlock } from '../../utils/guards'
import { GENERAL_ERR_CONTENT, NO_OUTPUT } from '../../constant'
import { useNavigate } from 'react-router-dom'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react'
import DataMatchOutputDropdown from '../DataMatchOutputDropdown'
import { motion } from 'framer-motion'
import dayjs from 'dayjs'
import { dataMatch } from '../../utils/ocr'
import TextcutRectangleBuilder from '../../utils/TextcutRectangleBuilder'
import { BasicSkeleton } from '../Skeleton'
import useSuccessFiles from '../../hooks/useSuccessFiles'
import { addMetric } from '../../utils/metrics'
import { isValidDate } from '../../utils/date-util'
import FilesTree from '../FilesTree'

const columnHelper = createColumnHelper<DataMatchSelection>()

const dataMatchInputOutputOverlapCheck = async (
  input: string,
  output: string[]
) => {
  const set = new Set(output)
  const noOutputCount = output.reduce((a, b) => {
    if (b === NO_OUTPUT) {
      return a + 1
    }
    return a
  }, 0)
  const setSize = output.includes(NO_OUTPUT)
    ? set.size + noOutputCount - 1
    : set.size + noOutputCount
  if (setSize !== output.length)
    return {
      pass: false,
      message: 'Duplicated output range',
    }
  const arr = await getColumnIndex(input, 0)
  if (arr?.some((val) => output.includes(val))) {
    return {
      pass: false,
      message: 'Overlapped input and output ',
    }
  }
  return {
    pass: true,
    message: '',
  }
}

const createDataMatchTextcutRectangle = (
  range: Excel.Range,
  block: TextBlock & Record<'fileId', unknown>,
  sheetId: string,
  sheetName: string,
  values: any,
  bindingGroupId: string | null = null,
  fileName: string | null = null
) => {
  const nano = nanoid()
  const vs = block.boundingPoly.vertices
  const topLeft = findTOpLeftPointFromVertices(vs)
  const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
  const builder = new TextcutRectangleBuilder()
  const top = topLeft?.y && block.pageH ? (topLeft?.y / block.pageH) * 100 : 0
  builder
    .addBoudingInfo({
      x: topLeft?.x ?? 0,
      y: topLeft?.y ?? 0,
      h,
      w,
    })
    .addSheetInfo({
      range: range.address.split('!')[1],
      sheetId: sheetId,
      sheetName: sheetName,
    })
    .addFileInfo({
      fileId: block.fileId as string,
      filePage: block.filePage!,
    })
    .addOcrInfo({ height: 0, id: '', width: 0 })
    .addStyle({ cellColor: '#C3E9FE', fill: '', stroke: '#A9E0FE' })
    .addBindingId(nano)
    .addDegee(0)
    .addTag(CutTag.DATA_MATCH)
    .addValues([[values]])
    .addTop(top)
  if (bindingGroupId) {
    builder.addBindingGroupId(bindingGroupId)
  }
  if (fileName) {
    builder.addFileName(fileName)
  }
  return builder.build()
}

const DataMatching = () => {
  const [firstRowHeader, setFirstRowHeader] = useState<0 | 1>(0)
  const [inputRange, setInputRange] = useState('')
  const [outputRange, setOutputRange] = useState<string[]>([])
  const [checkAllFiles, setCheckAllFiles] = useState(false)
  const [loading, setLoading] = useState(false)
  const isLocalMode = useAppSelector((state) => state.localMode.isLocalMode)
  const {
    files: data,
    isError,
    isLoading,
    error,
    isFetching,
    refetch,
  } = useSuccessFiles()
  const [primaryColumnOptions, setPrimaryColumnOptions] = useState<string[]>([])
  const [primaryIndiecs, setPrimaryIndices] = useState<number[]>([])
  const navigate = useNavigate()
  const [files, setFiles] = useState<TestFileCheck[]>([])
  const inputRangeRef = useRef<HTMLInputElement>(null)
  const [inputRangeFocus, setInputRangeFocus] = useState(true)
  const [stepper, setStepper] = useState<0 | 1 | 2 | -1>(0)

  const [tableData, setTableData] = useState<DataMatchSelection[]>([])
  const [outputTableError, setOutputTableError] = useState(false)
  const allowCancel = useRef(true)
  const [matchError, setMatchError] = useState(false)
  const [folders, setFolders] = useState<EPlusFolder[]>([])

  const onCompulsoryCheckboxChange =
    (info: CellContext<DataMatchSelection, string>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = info.getValue()
      const index = primaryColumnOptions.findIndex((option) =>
        value.startsWith(option)
      )
      if (index === -1) return
      if (primaryIndiecs.includes(index)) {
        const filtered = primaryIndiecs.filter((val) => val !== index)
        setPrimaryIndices(filtered)
      } else {
        setPrimaryIndices([...primaryIndiecs, index])
      }
    }
  const columns = [
    columnHelper.accessor('input', {
      cell: (info) => {
        return (
          <span className="relative flex justify-between">
            {info.getValue()}
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              as="div"
              show={true}
            >
              <Popover className="relative">
                <PopoverButton
                  className={
                    'hover:bg-gray-200 transition ease-in-out delay-75 hover:scale-110 rounded'
                  }
                >
                  <MoreHorizOutlinedIcon />
                </PopoverButton>

                <PopoverPanel className="absolute z-10 bg-white border rounded w-40">
                  <div className="flex items-center p-2">
                    <input
                      checked={primaryIndiecs.some((index) =>
                        info
                          .getValue()
                          .startsWith(primaryColumnOptions.at(index) ?? '@@@')
                      )}
                      type="checkbox"
                      onChange={onCompulsoryCheckboxChange(info)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="ms-2 text-sm font-medium text-gray-900  w-full ">
                      <p>Must find this data</p>
                    </label>
                  </div>
                </PopoverPanel>
              </Popover>
            </Transition>
          </span>
        )
      },
      header: 'Input Columns',
    }),
    columnHelper.accessor('output', {
      cell: (info) => info.getValue(),
      header: 'Output Columns',
    }),
  ]
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data) {
      setFolders(data.filter((d) => d.type === 'FOLDER'))
      const arr: TestFileCheck[] = data
        .filter((file) => file.type !== 'FOLDER')
        .filter((file) => file.status === FileStatus.SUCCEEDED)
        .map((file) => ({
          filename: file.fileName ?? '',
          checked: false,
          id: file.fileId,
          ocrPath: file.ocrFileKey,
          path: file.ocrFileKey,
          parentFolderId: file.parentFolderId,
        }))
      setFiles(arr)
      setCheckAllFiles(false)
    }
  }, [data])

  useEffect(() => {
    const func = async () =>
      Excel.run(async (ctx) => {
        const sheet = ctx.workbook.worksheets.getActiveWorksheet()
        if (inputRange === '') return []
        const range = sheet.getRange(inputRange)
        range.load(['columnCount', 'columnIndex', 'isNullObject'])
        await ctx.sync()
        if (range.isNullObject) return []
        const arr: string[] = []
        for (
          let i = range.columnIndex;
          i < range.columnIndex + range.columnCount;
          i++
        ) {
          arr.push(numberToLetters(i))
        }
        return arr
      })

    func()
      .then((res) => {
        setPrimaryColumnOptions(res)
        setPrimaryIndices([])
      })
      .catch((err) => {
        console.error(err)
        setPrimaryColumnOptions([])
      })
  }, [inputRange])

  useEffect(() => {
    const handler = async (event: Event) => {
      if (!isCustomEvent(event)) return
      try {
        const args: Excel.WorksheetSelectionChangedEventArgs = JSON.parse(
          event.detail
        )
        if (inputRangeFocus && inputRangeRef.current) {
          setInputRange(args.address)
          inputRangeRef.current.focus()
        }
      } catch (err) {
        toast.error(`Error: ${err}`)
      }
    }

    window.addEventListener('OnExcelWorkbooksSelectionChange', handler)
    return () =>
      window.removeEventListener('OnExcelWorkbooksSelectionChange', handler)
  }, [inputRangeFocus])

  const getInitialOutputCols = useCallback(
    async (noOutput = false) => {
      const columns = await getColumnIndex(inputRange, firstRowHeader)
      if (!columns) return
      const num = columns.length
        ? lettersToNumber(columns[columns.length - 1][0])
        : -1
      const arr = columns.map((_, i) =>
        noOutput ? NO_OUTPUT : numberToLetters(num + i)
      )
      setOutputRange(arr)
    },
    [firstRowHeader, inputRange]
  )

  useEffect(() => {
    getInitialOutputCols().catch((err) => console.error(err))
  }, [getInitialOutputCols])

  useEffect(() => {
    const func = () => getColumnIndex(inputRange, firstRowHeader)

    func()
      .then((columns) => {
        if (!columns) return
        const arr: DataMatchSelection[] =
          columns.map((index, i) => {
            const setSelected = (index: number) => (val: string) => {
              const output = [...outputRange]
              output[index] = val
              setOutputRange(output)
            }
            return {
              input: index,
              output: (
                <DataMatchOutputDropdown
                  data={generateExcelColumns()}
                  index={i}
                  selected={outputRange.at(i) || NO_OUTPUT}
                  setSelected={setSelected(i)}
                />
              ),
            }
          }) ?? []
        setTableData(arr)
      })
      .catch((err) => console.error(err))
  }, [inputRange, firstRowHeader, outputRange])

  useEffect(() => {
    const arr = outputRange.filter((r) => r !== NO_OUTPUT)
    const set = new Set(arr)
    if (
      set.size !== arr.length ||
      [...set.values()].some((val) => inputRange.includes(val))
    ) {
      setOutputTableError(true)
    } else {
      setOutputTableError(false)
    }
  }, [outputRange, inputRange])

  useEffect(() => {
    if (files.every((f) => f.checked) && !checkAllFiles) {
      setCheckAllFiles(true)
    } else if (files.some((f) => !f.checked) && checkAllFiles) {
      setCheckAllFiles(false)
    }
  }, [files, checkAllFiles])

  const onCheckAllFilesChange = () => {
    const arr = files.map((file) => ({
      ...file,
      checked: checkAllFiles ? false : true,
    }))
    setCheckAllFiles(!checkAllFiles)
    setFiles(arr)
  }

  // const onSingleFileCheck = (id: string) => () => {
  //   const newArr = map(files, (f) =>
  //     f.id === id ? { ...f, checked: !f.checked } : f
  //   )
  //   const allChecked = every(newArr, 'checked')
  //   setCheckAllFiles(allChecked ?? false)
  //   setFiles(newArr)
  // }

  const onInputRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value.substring(2, event.target.value.length)
    setInputRange(val)
    if (val)
      selectRange(val).catch((err) => {
        console.error(err)
        console.error('val:', val)
      })
  }

  const dispatchPushToUndoStack = (
    preValues: any[][],
    reference: TextcutRectangle,
    type: 'ADD' | 'DELETE',
    references: TextcutRectangle[] = []
  ) => {
    const event: UndoEvent = {
      id: nanoid(),
      preValues,
      reference,
      type,
      references,
    }
    dispatch(pushToUndoStack(event))
  }

  const onMatchDocumentClick = () => {
    const func = async () => {
      setLoading(true)
      allowCancel.current = false
      const inputValues = await loadTextFromRange(inputRange, outputRange)
      const filteredPrimaryIndices = primaryIndiecs.filter(
        (i) => outputRange[i] !== NO_OUTPUT
      )

      const inputFiles = filter(files, (file) => file.checked)
      if (inputFiles.length < 1) {
        toast.error('Please upload or select at least one file', {})
        return
      }

      const pass = await dataMatchInputOutputOverlapCheck(
        inputRange,
        outputRange
      )
      if (!pass.pass) {
        toast.error(pass.message, {})
        return
      }

      const filteredOutputRange = outputRange.filter((r) => r !== NO_OUTPUT)
      const fetchFilesStart = dayjs()

      // for (const file of inputFiles) {
      //   const [, ocr, dateCache] = await getFileAndOcr(file.id, isLocalMode)
      //   file.ocrJson = ocr
      //   file.dateCache = dateCache
      // }

      /**
       * Fetch files and ocr in batch
       */
      const fileIds = inputFiles.map((file) => file.id)
      const results = await getFileAndOcrBatch(fileIds, isLocalMode)
      const resultHashmap = new Map<string, (typeof results)[0]>()
      results.forEach((result) => {
        const [id, , ,] = result
        resultHashmap.set(id, result)
      })

      inputFiles.forEach((file) => {
        const fileData = resultHashmap.get(file.id)
        if (!fileData)
          throw new Error(`Internal error: file ${file.id} is invalid`)
        const [, , ocr, dateCache] = fileData
        file.ocrJson = ocr
        file.dateCache = dateCache
      })

      const fetchFileEnd = dayjs()
      console.log(
        'Fetch files takes:',
        fetchFileEnd.diff(fetchFilesStart, 'millisecond')
      )
      allowCancel.current = true
      const dataMatchStart = dayjs()
      const result = dataMatch(
        inputFiles,
        inputValues.textResult,
        inputValues.valueResult,
        filteredPrimaryIndices,
        firstRowHeader
        // isLocalMode
      )
      const dataMatchEnd = dayjs()
      console.log(
        'data match takes:',
        dataMatchEnd.diff(dataMatchStart, 'millisecond')
      )

      const arr = result.map((re, i) => {
        if (re.length > 1) {
          const returnArray: any[] = re[0].map((_) => null)
          for (let j = 0; j < returnArray.length; j++) {
            const allNull = re.every((val) => val[j] === null)
            if (allNull) {
              returnArray[j] = null
            } else {
              const noneNullCount = re.filter((val) => val[j] !== null).length
              let noneNull: any = null
              if (noneNullCount > 1) {
                noneNull = DataMatchStatus.DUPLICATE
              } else {
                noneNull = re.find((val) => val[j] !== null)
              }
              if (noneNull === DataMatchStatus.DUPLICATE) {
                returnArray[j] = noneNull
              } else if (noneNull === null) {
                returnArray[j] = null
              } else if (isTextBlock(noneNull)) {
                if (isValidDate(noneNull.description)) {
                  return inputValues.textResult.at(i)?.at(j)
                }
                return inputValues.valueResult.at(i)?.at(j)
              } else returnArray[j] = ''
            }
          }
          return returnArray
          // return re[0].map((val) => {
          //   if (val == null) return ''
          //   else if (isTextBlock(val)) return DataMatchStatus.DUPLICATE
          //   else if (typeof val === 'string') return val
          //   return ''
          // })
        }
        return re[0].map((val, j) => {
          if (val == null) return ''
          else if (typeof val === 'string') return val
          else if (isTextBlock(val)) {
            if (isValidDate(val.description)) {
              return inputValues.textResult.at(i)?.at(j)
            }
            return inputValues.valueResult.at(i)?.at(j)
          } else return ''
        })
      })
      // console.log(result)
      // const arr = result.map((row) =>
      //   row.map((val: any) => {
      //     if (val === null) {
      //       return ''
      //     } else if (typeof val === 'string') {
      //       return val
      //     } else if (isTextBlock(val)) {
      //       return val.description
      //     } else return ''
      //   })
      // )
      allowCancel.current = false
      await Excel.run(async (ctx) => {
        ctx.runtime.load('enableEvents')
        await ctx.sync()
        if (ctx.runtime.enableEvents) {
          ctx.runtime.enableEvents = !ctx.runtime.enableEvents
          await ctx.sync()
        }
        const sheet = ctx.workbook.worksheets.getActiveWorksheet()
        const iRange = sheet.getRange(inputRange)
        iRange.load(['rowCount', 'rowIndex'])
        await ctx.sync()

        const outputRanges: Excel.Range[][] = []

        for (let i = 0; i < arr.length; i++) {
          const row: Excel.Range[] = []
          for (let j = 0; j < filteredOutputRange.length; j++) {
            const letter = lettersToNumber(filteredOutputRange[j])
            const range = sheet.getRangeByIndexes(
              iRange.rowIndex + i,
              letter - 1,
              1,
              1
            )
            range.load(['address', 'rowIndex', 'columnIndex', 'values'])
            row.push(range)
          }
          outputRanges.push(row)
        }
        await ctx.sync()
        sheet.load(['id', 'name'])
        await ctx.sync()
        if (firstRowHeader) {
          for (let i = 0; i < outputRanges[0].length; i++) {
            if (outputRanges[0][i].values[0][0] !== '') {
              arr[0][i] = outputRanges[0][i].values[0][0]
            }
          }
        }
        const rects: TextcutRectangle[] = []
        for (let i = 0; i < arr.length; i++) {
          const findingsOfTheRow = result[i]
          const rangesOfTheRow = outputRanges[i]
          for (let j = 0; j < rangesOfTheRow.length; j++) {
            if (arr[i][j] === '' || arr[i][j] === DataMatchStatus.NOT_FOUND)
              continue
            else if (arr[i][j] === DataMatchStatus.DUPLICATE) {
              const bindingGroupId = nanoid()
              const findings = findingsOfTheRow.map((f) => f[j])
              const elems: TextcutRectangle[] = []
              for (const finding of findings) {
                if (
                  finding == null ||
                  !isTextBlock(finding) ||
                  !('fileId' in finding) ||
                  !('filePage' in finding)
                )
                  continue
                const f = files.find((file) => file.id === finding.fileId)
                if (!f) continue
                const range = rangesOfTheRow[j]
                const elem = createDataMatchTextcutRectangle(
                  range,
                  finding,
                  sheet.id,
                  sheet.name,
                  arr[i][j],
                  bindingGroupId,
                  f.filename
                )
                if (elem) {
                  rects.push(elem)
                  elems.push(elem)
                }
              }

              if (elems.length) {
                dispatchPushToUndoStack(
                  rangesOfTheRow[j].values,
                  elems[0],
                  'ADD',
                  elems
                )
              }
            } else {
              const finding = findingsOfTheRow[0][j]
              if (
                finding == null ||
                !isTextBlock(finding) ||
                !('fileId' in finding)
              )
                continue
              const range = rangesOfTheRow[j]
              const elem = createDataMatchTextcutRectangle(
                range,
                finding,
                sheet.id,
                sheet.name,
                arr[i][j]
              )
              if (elem) {
                rects.push(elem)
                dispatchPushToUndoStack(range.values, elem, 'ADD')
              }
            }
          }
        }
        // for (let i = 0; i < outputCols.length; i++) {
        //   const c = arr.map((val) => val[i])
        //   const mapped = c.map((val) => [val])
        //   const mappedResult = result.map((re) => re[i])
        //   const preValues = outputCols[i]!.values
        // outputCols[i]!.values = mapped
        // for (let j = 0; j < mapped.length; j++) {
        //   if (firstRowHeader && !j) continue
        //   const cell = outputCols[i]!.getCell(j, 0)
        //   if (mappedResult[j][0] && isTextBlock(mappedResult[j][0])) {
        //     cell.format.font.color = 'black'
        //     const nano = nanoid()
        //     const vs = mappedResult[j][0].boundingPoly.vertices
        //     const topLeft = findTOpLeftPointFromVertices(vs)
        //     const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
        //     const rect: TextcutRectangle = {
        //       ...mappedResult[j][0],
        //       rangeAddr: `${numberToLetters(outputCols[i]!.columnIndex)}${
        //         iRange.rowIndex + j + 1
        //       }`,
        //       sheetId: sheet.id,
        //       degree: 0,
        //       x: topLeft?.x ?? 0,
        //       y: topLeft?.y ?? 0,
        //       h,
        //       w,
        //       cH: 0,
        //       cW: 0,
        //       tag: CutTag.DATA_MATCH,
        //       bindingId: nano,
        //       sheetName: sheet.name,
        //       stroke: '#A9E0FE',
        //       fill: '',
        //       values: [[mapped[j][0]]],
        //       cellColor: '#C3E9FE',
        //     }
        //     rects.push(rect)
        //     dispatchPushToUndoStack(preValues[j][0], rect, 'ADD')
        //   }
        // }
        // }
        const bindingSet = new Set<string>()
        for (const elem of rects) {
          if (elem.bindingGroupId && bindingSet.has(elem.bindingGroupId))
            continue
          const range = sheet.getRange(elem.rangeAddr)
          createBindingAndAddOnSelectionHandler(
            ctx,
            range,
            'Range',
            elem.bindingId
          )
          if (elem.bindingGroupId) {
            bindingSet.add(elem.bindingGroupId)
          }
        }
        await ctx.sync()
        await addMetric({
          namespace: MetricNamespace.DATA_MATCH,
          metric_value: 1,
          metric_name: MetricName.COUNT,
        })
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < arr[i].length; j++) {
            const range = outputRanges[i][j]
            range.values = [[arr[i][j]]]
            range.format.horizontalAlignment = Excel.HorizontalAlignment.right
            range.format.autofitColumns()
            range.format.font.name = 'Segoe UI'
            range.format.fill.color = '#C3E9FE'
          }
        }
        await ctx.sync()
        await dispatch(addMultipleReferences(rects))
      })
    }
    const start = dayjs()
    func()
      .catch((err) => {
        console.error(err)
        setMatchError(true)
        toast.error(`Error: ${err}`)
      })
      .finally(async () => {
        const end = dayjs()
        console.log('time:', end.diff(start, 'ms'))
        await enableEvents().catch((err) =>
          console.error(`enableEvents error=${err}`)
        )
        allowCancel.current = true
        setLoading(false)
      })
  }

  const resetMatchError = async () => {
    try {
      await enableEvents()
      setMatchError(false)
    } catch (error) {
      toast.error(`resetMatchError error=${error}`)
    }
  }

  const selectRange = (range: string) =>
    Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getActiveWorksheet()
      const r = sheet.getRange(range)
      r.select()
      await ctx.sync()
    })

  const renderErrorTitle = () => {
    // if (isLocalMode && localError)
    //   return getOfficeErrorTitle(localError.message)
    return isOfficeError(error)
      ? getOfficeErrorTitle(error.message)
      : 'Internal Error'
  }

  const allowStepTwo = () => {
    if (inputRange) {
      setStepper(1)
      setInputRangeFocus(false)
    } else toast.error('Please select input')
  }

  const allowStepThree = () => {
    const count = files.reduce((a, b) => {
      if (b.checked) return a + 1
      return a
    }, 0)
    if (count > 0) {
      setStepper(2)
    } else toast.error('Please select or upload at least one file')
  }

  const inputRangeElem = () => (
    <>
      <div className="bg-white rounded px-3 pt-2">
        <input
          id="InputRange"
          ref={inputRangeRef}
          type="text"
          className="w-full h-7  my-2 p-1 px-2 border-2 rounded  text-black border-b-[#616161] "
          value={`= ${inputRange}`}
          onChange={onInputRangeChange}
        />
        <div className="flex items-center justify-between">
          <div>
            <input
              type="checkbox"
              value={firstRowHeader}
              checked={firstRowHeader === 1}
              onChange={() => setFirstRowHeader(firstRowHeader ? 0 : 1)}
              onClick={() => setFirstRowHeader(firstRowHeader === 0 ? 1 : 0)}
              size={20}
              className="mr-2 accent-[#107C41]"
            />
            <label htmlFor="include-heading">First row including heading</label>
          </div>
        </div>

        <div className="flex flex-row-reverse">
          <button
            className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
            onClick={allowStepTwo}
          >
            Next
          </button>
        </div>
      </div>
    </>
  )

  const fileStructure = getFileStructure(folders, files)

  const filesElem = ({
    navigate,
  }: {
    navigate: ReturnType<typeof useNavigate>
  }) => {
    return (
      <>
        {(isLoading || isFetching) && (
          <div className="w-full h-full p-2">
            <BasicSkeleton />
          </div>
        )}
        {isError && (
          <div className="flex flex-col p-4">
            <ErrorMsg
              title={renderErrorTitle()}
              content={GENERAL_ERR_CONTENT}
              navigate={navigate}
            />
          </div>
        )}
        {!isError && !isLoading && !isFetching && (
          <div className="bg-white px-3 pt-2 rounded max-h-96 h-fit">
            <div className="flex flex-col max-h-64 overflow-y-auto basis-2/3">
              <div className="flex justify-between items-center ">
                {files.length > 0 && (
                  <div className="flex items-center p-2">
                    <input
                      type="checkbox"
                      name="select-all"
                      id=""
                      className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
                      checked={checkAllFiles}
                      onChange={onCheckAllFilesChange}
                    />
                    <label htmlFor="select-all">Select all</label>
                  </div>
                )}
                {!files.length && <p>No processed files identified</p>}
              </div>

              <FilesTree
                fileStructure={fileStructure}
                files={files}
                folders={folders}
                setFiles={setFiles}
              />

              {/* {files.map((file) => (
                <div
                  className="flex justify-between items-center"
                  key={file.id}
                >
                  <div className="flex items-center p-2">
                    <input
                      type="checkbox"
                      name="select-all"
                      id=""
                      className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
                      checked={file.checked}
                      onChange={onSingleFileCheck(file.id)}
                    />
                    <label htmlFor="select-all">{file.filename}</label>
                  </div>
                </div>
              ))} */}
            </div>

            <div className="flex justify-between items-center basis-1/3">
              <div className="flex mt-4 h-fit">
                <button
                  type="button"
                  className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300"
                  onClick={() => refetch()}
                >
                  {'Refresh'}
                </button>
              </div>
              <div className="mt-4 flex flex-row-reverse justify-start h-fit">
                <button
                  type="button"
                  className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
                  onClick={allowStepThree}
                >
                  {'Next'}
                </button>
                <button
                  type="button"
                  className="min-w-16  inline-flex m-1 text-teal-600 justify-center rounded-full border border-teal-500 px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium hover:bg-gray-100"
                  onClick={() => {
                    setStepper(0)
                    setInputRangeFocus(true)
                  }}
                >
                  {'Prev'}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  const outputElem = () => (
    <>
      <div className="bg-white px-3 pt-2 rounded">
        <div className="flex w-full justify-center">
          <table className="border w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b-2">
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="p-2 border-r-2">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="border-b-2 hover:bg-gray-100">
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="border-r-2 p-2 ">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {outputTableError && (
          <div className="flex w-full animate-pulse z-0">
            <div
              className="p-2 bg-red-100 items-center text-red-700 leading-none lg:rounded-full flex lg:inline-flex w-full"
              role="alert"
            >
              <span className="flex rounded-full bg-red-300 uppercase px-2 py-1 text-xs font-bold mr-3">
                Error
              </span>
              <span className="font-semibold mr-2 text-left flex-auto">
                Duplicated input / output
              </span>
              {/* <span>
                <button
                  className="hover:bg-red-200 rounded transition  hover:scale-105"
                  onClick={() => setOutputTableError(false)}
                >
                  <CloseOutlinedIcon />
                </button>
              </span> */}
            </div>
          </div>
        )}

        <div className="flex justify-between">
          <div className="mt-4 ">
            <button
              type="button"
              className="inline-flex m-1  hover:text-white border border-yellow-400 hover:bg-yellow-500  font-medium rounded-2xl text-xs sm:text-sm px-2 py-1 sm:px-4 sm:py-2 text-center"
              onClick={() =>
                getInitialOutputCols(true).then(() =>
                  setOutputTableError(false)
                )
              }
            >
              {'Clear Outputs'}
            </button>
          </div>
          <div className="mt-4 flex flex-row-reverse justify-start h-fit">
            <button
              disabled={outputTableError}
              type="button"
              className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
              onClick={onMatchDocumentClick}
            >
              {'Match files'}
            </button>
            <button
              type="button"
              className="min-w-16  inline-flex m-1 text-teal-600 justify-center rounded-full items-center border border-teal-500 px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium hover:bg-gray-100"
              onClick={() => setStepper(1)}
            >
              {'Prev'}
            </button>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <motion.div
      // initial={{ scaleX: 0 }}
      // animate={{ scaleX: 1, transition: { duration: 0.5, ease: 'circOut' } }}
      // exit={{ scaleX: 1, transition: { duration: 0.5, ease: 'circIn' } }}
      // style={{ originX: isPresent ? 0 : 1 }}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      className="h-full"
    >
      <div className="flex flex-col h-full w-full bg-[#f5f5f5] overflow-y-auto p-4 sm:p-8">
        <ToastContainer stacked={true} className="max-w-72" />
        {loading && !isError && !matchError && (
          <div className="flex flex-col justify-center items-center h-full w-full gap-2">
            <div>
              <ClockLoader size={50} color="#36d7b7" />
            </div>
            <div className="font-semibold text-lg">Data match in progress </div>
            <div>
              <button
                className={`bg-slate-200 p-2 text-md font-medium ${
                  allowCancel.current
                    ? 'hover:bg-slate-300 rounded '
                    : 'cursor-not-allowed'
                }`}
                onClick={() => {
                  setLoading(false)
                  window.location.reload()
                }}
                disabled={!allowCancel.current}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {!loading && !matchError && (
          <>
            <ol className="relative border-s-2 hidden sm:block">
              <li className="mb-10 ms-4">
                <span className="absolute flex items-center justify-center w-5 h-5 bg-green-50  -start-3 ">
                  {stepper > 0 && (
                    <CheckOutlinedIcon sx={{ fontSize: 18, color: 'gray' }} />
                  )}
                </span>
                <CustomDisclosure
                  header="1. Select input range."
                  element={inputRangeElem()}
                  open={stepper === 0 ? true : false}
                  defaultOpen={true}
                />
              </li>
              <li className="mb-10 ms-4">
                <span className="absolute flex items-center justify-center w-5 h-5 bg-green-50  -start-3 ">
                  {stepper > 1 && (
                    <CheckOutlinedIcon sx={{ fontSize: 18, color: 'gray' }} />
                  )}
                </span>
                <CustomDisclosure
                  element={filesElem({ navigate })}
                  header="2. Select supporting documents or folders if they belong to different types."
                  open={stepper === 1 ? true : false}
                  defaultOpen={true}
                />
              </li>
              <li className="ms-4">
                <span className="absolute flex items-center justify-center w-5 h-5 bg-green-50  -start-3 ">
                  {/* <CheckOutlinedIcon sx={{ fontSize: 18, color: 'gray' }} /> */}
                </span>
                <CustomDisclosure
                  header="3. Select output range."
                  element={outputElem()}
                  open={stepper === 2 ? true : false}
                  defaultOpen={true}
                />
              </li>
            </ol>
            <div className="flex flex-col w-full sm:hidden gap-4">
              <CustomDisclosure
                header="1. Select input range."
                element={inputRangeElem()}
                open={stepper === 0 ? true : false}
                defaultOpen={true}
              />
              <CustomDisclosure
                element={filesElem({ navigate })}
                header="2. Select supporting documents or folders if they belong to different types."
                open={stepper === 1 ? true : false}
                defaultOpen={true}
              />
              <CustomDisclosure
                header="3. Select output range."
                element={outputElem()}
                open={stepper === 2 ? true : false}
                defaultOpen={true}
              />
            </div>
          </>
        )}
        {matchError && (
          <div className="flex w-full">
            <ErrorMsg
              title={'Data Match Error'}
              content={GENERAL_ERR_CONTENT}
              refetch={resetMatchError}
            />
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default DataMatching
